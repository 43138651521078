export const ventilatorDataConfig = [
    {
        name: 'RR',
        key: 'RR',
        value: 50,
        color: 'blue-dark'
    },
    {
        name: 'VTe (ml)',
        key: 'VTe',
        value: 80,
        color: 'orange-700-accent'
    },
    {
        name: 'PEEP',
        key: 'peep',
        color: 'pink-shiny',
        value: 30
    },
    {
        name: 'FIO2',
        color: 'green-800',
        key: 'FiO2',
        value: 30
    },
    {
        name: 'PIP',
        key: 'PIP',
        color: 'red-500',
        value: 10
    },
    {
        name: 'MVE',
        key: 'MVe',
        color: 'primary',
        value: 10
    },
    {
        name: 'V PEAK',
        key: 'Vpeak',
        color: 'black',
        value: 70
    }
]

export const syringPumpDataConfig = [
    {
        name: 'Drug name',
        key: 'Drug Name',
        color: 'orange-700-accent',
        value: 'Morphine'
    },
    {
        name: 'Flow rate (ml /h)',
        key: 'Rate',
        color: 'red-500',
        value: 10
    },
    {
        name: 'Total (ml)',
        key: 'Total',
        color: 'black',
        value: 30
    }
]